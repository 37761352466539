<template>
  <base-material-card icon="mdi-clipboard-text" :title="name" class="px-5 py-3">
    <div class="listing--form-container">
      <page-loading :show="loading" />
      <form @submit.prevent="submit">
        <div class="form--group row" :class="{ 'has-error': validation.hasError('name') }">
          <label class="col-12 col-lg-3" for="nameField">
            <span>Nama Agensi</span>
          </label>
          <div class="col-12 col-lg-6">
            <v-text-field
              id="nameField"
              v-model="name"
              type="text"
              name="name"
              outlined
              hide-details
              :readonly="readOnly"
            />
            <span class="val-error">{{ validation.firstError('name') }}</span>
          </div>
        </div>
        <div
          class="form--group row"
          :class="{ 'has-error': validation.hasError('defaultAgentOffice') }"
        >
          <label class="col-12 col-lg-3" for="defaultAgentOfficeField">
            <span>Kantor Agen Default</span>
          </label>
          <div class="col-12 col-lg-6">
            <multiselect
              name="defaultAgentOffice"
              id="defaultAgentOfficeField"
              v-model="defaultAgentOffice"
              :options="agentOffices"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
            />
            <span class="val-error">{{ validation.firstError('defaultAgentOffice') }}</span>
          </div>
        </div>
        <div class="d-flex align-center justify-end" v-if="readOnly !== true">
          <v-btn color="primary" class="bottom--button" type="submit">
            {{ $t('agency.btn.save') }}
          </v-btn>
        </div>
      </form>
    </div>
  </base-material-card>
</template>

<script>
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');

const PageLoading = () => import('@/components/content-loading/page-loading.vue');
import { mapState, mapGetters } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'agency-form',
  mixins: [HelperMixin],
  props: ['readOnly'],
  components: {
    Multiselect,
    PageLoading,
  },
  computed: {
    ...mapState({
      formType: (state) => state.agency.form.formType,
      agentOffices: (state) => state.agency.form.agentOffices,
    }),
    ...mapGetters({}),
    loading: {
      get() {
        return this.$store.state.agency.form.loading;
      },
      set(value) {
        this.$store.commit('agency/form/SET_LOADING', value);
      },
    },
    name: {
      get() {
        return this.$store.state.agency.form.name;
      },
      set(value) {
        this.$store.commit('agency/form/SET_NAME', value);
      },
    },
    defaultAgentOffice: {
      get() {
        return this.$store.state.agency.form.defaultAgentOffice;
      },
      set(value) {
        this.$store.commit('agency/form/SET_DEFAULT_AGENT_OFFICE', value);
      },
    },
  },
  validators: {
    name(value) {
      return Validator.value(value).required(this.$i18n.t('errors.agency.name.required'));
    },
    defaultAgentOffice(value) {
      if (this.formType === 'EDIT') {
        return Validator.value(value).required(
          this.$i18n.t('errors.agency.defaultAgentOffice.required'),
        );
      } else {
        return Validator.value(value);
      }
    },
  },
  methods: {
    validate() {
      let validationArray = [];
      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function (results) {
        if (
          results.filter(function (result) {
            console.log('WHICH SECTION FAILED: ', result);
            return !result;
          }).length === 0
        ) {
          console.log('ALL VALID!');
          return true;
        }
        console.log('SOME INVALID!');
        return false;
      });
    },
    async submit() {
      if (await this.validate()) {
        await this.$store.dispatch('agency/form/postData');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('agency.save.successMsg'),
          'success',
        );
        this.$router.back();
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>
